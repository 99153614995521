import React from "react";

function ContactFooter() {
  return (
    <div style={{ background: "#252525", padding: "20px 0" }}>
      <section>
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the content
            alignItems: "center", // Vertically center the content
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="https://ik.imagekit.io/39dbkqr1gb/VCSWhite.webp"
              alt=""
              style={{ width: "auto", height: "90px", display: "block" }}
            />
          </div>

          <div style={{ textAlign: "center", color: "#fff", marginTop: "20px" }}>
            <p>Copyright VCS Billing © 2024. All rights reserved.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactFooter;
