import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function OurServicesBody() {
  // eslint-disable-next-line no-unused-vars
  const [isWideScreen, setIsWideScreen] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    setIsWideScreen(mediaQuery.matches);
    const handler = (e) => setIsWideScreen(e.matches);
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  };

  const serviceItemStyle = {
    cursor: "pointer",
  };

  // Media query for screens wider than 768px
  const mediaQuery = window.matchMedia("(min-width: 768px)");
  if (mediaQuery.matches) {
    containerStyle.flexDirection = "row";
    containerStyle.justifyContent = "space-evenly";
    containerStyle.flexWrap = "wrap";
    serviceItemStyle.flexBasis = "calc(50% - 1rem)";
  }

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(https://ik.imagekit.io/39dbkqr1gb/AdobeStock_223963684.jpeg?updatedAt=1728942440769)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.7,
          zIndex: -1,
        }}
      ></div>
      <div className="ourServices">
        <div className="container servicesParentDiv">
          <div className="container servicesOverview wrapper">
            <div className="centerText ">
              <img
                src="https://ik.imagekit.io/39dbkqr1gb/whiteC.webp"
                className="ourServC"
                alt="whiteC"
              />
              <h1>Our Services</h1>
            </div>
            <div
              className=""
              style={{ padding: 10 }}
              onClick={() => navigate("/aboutUs")}
            >
              <div className="centerText">
                <motion.h2 style={serviceItemStyle}>
                  • Professional Services Since 1981 •
                </motion.h2>
                <motion.h2 style={serviceItemStyle}>
                  • Manufactured Housing Specialists •
                </motion.h2>{" "}
                <motion.h2 style={serviceItemStyle}>
                  • 100,000+ Satisfied Residents and Tenants •
                </motion.h2>
              </div>
            </div>
            <div style={containerStyle}>
              <motion.div
                style={serviceItemStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 0.5 }}
                // Pass Header and icon as props to the function handleNavigation to show moreDetails page
                // onClick={() =>
                //   handleNavigate("ACCOUNTING SERVICE", "fa-solid fa-calculator")
                // }
              >
                <motion.h5 whileHover={{ scale: 1.02 }}>
                  <i className="fa-solid fa-calculator"></i> ACCOUNTING SERVICE
                </motion.h5>
                <p>
                  Our comprehensive accounting services streamline your business
                  operations, giving you peace of mind and allowing you to focus
                  on growing your business.
                </p>
              </motion.div>
              <motion.div
                style={serviceItemStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 0.5 }}
                // onClick={() =>
                //   handleNavigate("BOOKKEEPING SERVICE", "fa-solid fa-file-alt")
                // }
              >
                <motion.h5
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  <i className="fa-solid fa-file-alt"></i> BOOKKEEPING SERVICE
                </motion.h5>
                <p>
                  Stay organized with our professional bookkeeping service. We
                  handle your day-to-day transactions, maintain accurate
                  records, and ensure your books are balanced.
                </p>
              </motion.div>
            </div>
            <div style={containerStyle}>
              <motion.div
                style={serviceItemStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1 }}
                // onClick={() =>
                //   handleNavigate("UTILITY BILLING", "fa-solid fa-house")
                // }
              >
                <motion.h5
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  <i className="fa-solid fa-house"></i> UTILITY BILLING
                </motion.h5>
                <p>
                  Efficiently manage your utility expenses with our
                  comprehensive utility billing services. We handle all aspects
                  of billing for property, from generating accurate utility
                  bills to deliverables of the statements via bulk or direct
                  mail. Our team ensures timely and precise billing, allowing
                  clients to focus on other critical aspects of their
                  operations. With our dedicated service, you can trust that
                  your billing needs are handled professionally and efficiently.
                </p>
              </motion.div>
              <motion.div
                style={serviceItemStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1 }}
                // onClick={() =>
                //   handleNavigate("METER READING", "fa-solid fa-clipboard-check")
                // }
              >
                <motion.h5
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  <i className="fa-solid fa-clipboard-check"></i> METER READING
                </motion.h5>
                <p>
                  Our dedicated team of meter readers visits mobile home parks
                  to accurately record electric, gas, and water meter readings.
                  We perform timely and precise data collection to support
                  efficient utility management and billing for your property.
                </p>
              </motion.div>
            </div>
            <div style={containerStyle}>
              <motion.div
                style={serviceItemStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1.5 }}
                // onClick={() =>
                //   handleNavigate(
                //     "UTILITY RATES AUDITING",
                //     "fa-solid fa-headset"
                //   )
                // }
              >
                <motion.h5
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  <i className="fa-solid fa-headset"></i> UTILITY RATES AUDITING
                </motion.h5>
                <p>
                  We perform deep dives into prior utility billing charges,
                  rates and compliance from other service providers and
                  platforms to help provide peace of mind. If you have a
                  regulatory or compliance concern, we can provide support.
                </p>
              </motion.div>
              <motion.div
                style={serviceItemStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "anticipate", duration: 1.5 }}
                // onClick={() =>
                //   handleNavigate(
                //     "RENT MANAGER UTILITY BILLING",
                //     "fa-solid fa-building"
                //   )
                // }
              >
                <motion.h5
                  whileHover={{
                    scale: 1.02,
                  }}
                >
                  <i className="fa-solid fa-building"></i> RENT MANAGER UTILITY
                  BILLING
                </motion.h5>
                <p>
                  Rent Manager is a fantastic property management platform and
                  we are dedicated to supporting your operations through this
                  vital platform. We perform utility billing within Rent Manager
                  so that you have all of your data consolidated in one place,
                  can run reporting, and always have your data available to you.
                  You are in control while we leverage what you already have,
                  use, and know.
                </p>
              </motion.div>
            </div>

            <div className="row justify-content-center">
              <a
                href="https://vcs.payrent.com/about"
                target="_blank"
                rel="noreferrer"
                className="text-white"
                style={{ textDecoration: "none" }}
              >
                <motion.div
                  className="col"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ ease: "anticipate", duration: 2.5 }}
                >
                  <motion.h5
                    whileHover={{
                      scale: 1.02,
                    }}
                  >
                    <i className="fa-solid fa-file-invoice-dollar"></i> ONLINE
                    PAYMENTS
                  </motion.h5>
                  <p>
                    We provide a simple and convenient way for residents to make
                    payments through our online portal. Accepting digital
                    payments improves cashflow, minimizes failed payments and
                    increases resident satisfaction.
                  </p>
                </motion.div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .services-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
        .service-item {
          text-align: center;
          cursor: pointer;
        }
        .wide-screen {
          flex-direction: row;
          justify-content: space-evenly;
          flex-wrap: wrap;
        }
        .wide-screen .service-item {
          flex-basis: calc(50% - 1rem);
        }
      `}</style>
    </div>
  );
}

export default OurServicesBody;
