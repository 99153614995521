import React, { useState, lazy } from "react";
import { getAuth, deleteUser, signInWithEmailAndPassword } from "firebase/auth";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));

const DeleteUser = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const auth = getAuth();
    try {
      // Re-authenticate the user with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Delete the user
      await deleteUser(user);
      alert("User deleted successfully");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: "linear-gradient(135deg, #f8f9fa, #e9ecef)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Circles */}
      <div
        style={{
          position: "absolute",
          width: "400px",
          height: "400px",
          backgroundColor: "#0181c0", // Blue
          borderRadius: "50%",
          top: "-150px",
          left: "-150px",
          zIndex: 0,
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          width: "250px",
          height: "250px",
          backgroundColor: "#90C541", // Green
          borderRadius: "50%",
          top: "150px",
          right: "-100px",
          zIndex: 0,
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          width: "140px",
          height: "140px",
          padding: 10,
          backgroundColor: "#ee2b39", // Red
          borderRadius: "50%",
          top: "200px",
          right: "-100px",
          left: "20%",
          zIndex: 0, 
        }}
      ></div>

      <Header />

      <Container
        style={{
          maxWidth: "500px",
          marginTop: "50px",
          flex: "1",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)", 
          padding: "40px",
          position: "relative",
          zIndex: 1,
          opacity: "95%"
        }}
      >
        <h2 className="text-center mb-4">Delete User Account</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{
                borderRadius: "5px",
              }}
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{
                borderRadius: "5px",
              }}
            />
          </Form.Group>

          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={loading}
            className="w-100"
            style={{
              borderRadius: "5px",
              backgroundColor: "#ee2b39", 
              borderColor: "#ee2b39",
            }}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Delete Account"
            )}
          </Button>
        </Form>
      </Container>

      <Footer style={{ zIndex: 2 }} />
    </div>
  );
};

export default DeleteUser;
