import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
//Creates AboutBody component for about us page
function AboutBody() {
  return (
    <div>
      <div>
        <div className="background2 d-block w-100">
          <section id="car">
            <div className=" background2">
              {/* Carousel styling reused for background image */}
              <div className="carousel-inner">
                <div className="carousel-item active aboutC"></div>
              </div>
              <div className="container aboutHeadContainer">
                <img
                  src="https://ik.imagekit.io/39dbkqr1gb/whiteC.webp"
                  alt="c"
                />
                <h3 data-testid="about" className="mx-auto aboutHeader">
                  <span style={{ fontWeight: "bold" }}>About Us</span>
                </h3>
              </div>
            </div>
          </section>
        </div>
        <div className="aboutUsText">
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>VCS Billing </strong>provides a full suite of utility
            sub-metering and billing services for multi-unit and multifamily
            properties. Established in 1981, today we serve thousands of
            residential and commercial units across diverse property landscape.
            While we are focused on the highly-regulated, complex manufactured
            housing industry in California, we extend our expertise out to
            nationwide service to great effect.
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>We provide service and support</strong> to large property
            management portfolios, vastly enhancing the value and critical
            services they provide their clients, and we provide support to
            smaller, owner-managed portfolios.{" "}
            <em>We serve at the pleasure of a diverse clientele.</em>
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>We are committed to your success! </strong>We recognize that
            our clients’ success is our success as well. Our exceptional Billing
            Specialists are well-trained to be efficient, effective, and highly
            responsive to client requests. <em>Communication is key.</em>
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>Our teams of dedicated professionals</strong> are here to
            serve our clients each step of the way; from onboarding to the
            monthly processing of client billing, and post-billing support.{" "}
            <em>We are glad to serve.</em>
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>
              We are much more than just a typical billing service.{" "}
            </strong>
            A key component of our business is our professional services which
            aim to take on client and outside special projects that we may be
            able to provide assistance with in a consulting or advisory
            capacity. We have a highly-skilled staff that is eager to offer up
            their knowledge and resources beyond-the-billing. This helps keep us
            attuned to the evolving needs of our clients and allows a vehicle
            for our own professional development.{" "}
            <em>We better ourselves to better serve our clients.</em>
          </motion.p>
          {/* <img src={teamSVG} className="SVG"alt="team1"></img> */}
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>
              We can propose existing, cost-effective solutions or we can help
              develop a catered solution to a specific situation.
            </strong>{" "}
            <em>We happily take on the tough challenges.</em>
          </motion.p>
          {/* <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>Manufactured Housing Specialists</strong> <em></em>
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>Professional Services Since 1981</strong> <em></em>
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>100,000+ Satisfied Residents and Tenants</strong>{" "}
            <em>At VCS we have over 100,000 satisfied residents and tenants</em>
          </motion.p>
          <motion.p
            className="mx-auto"
            initial={{ opacity: 0, y: 20 }} // Start position
            animate={{ opacity: 1, y: 0 }} // End position (final state)
            transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth transition
          >
            <strong>Community-tailored Solutions</strong> <em></em>
          </motion.p> */}
          <div className="aboutContactBtn centerText">
            <Link
              className="btn btn-outline-primary"
              data-testid="contactLink"
              to="/contactpage"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
//Exports about body component for about us page
export default AboutBody;
