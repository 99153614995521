import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import { servicesData, paragraphTwo } from "./data/serviceData";

function MoreDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { header, icon } = location.state || {};
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!header) {
      navigate("/ourServices");
    }
  }, [header, navigate]);
  // Gets body for the header prop with servicesData
  const body = servicesData[header] || "Description not available.";
  const moreBody = paragraphTwo[header] || "";
  return (
    <>
      <Header />
      <div
        style={{
          position: "relative",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(https://ik.imagekit.io/39dbkqr1gb/AdobeStock_223963684.jpeg?updatedAt=1728942440769)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.7,
            zIndex: -1,
          }}
        ></div>
        <div className="ourServices bodyParentDiv">
          <div className="container servicesParentDiv">
            <div className="container servicesOverview wrapper">
              <div className="col-lg-12 centerText">
                <img
                  src="https://ik.imagekit.io/39dbkqr1gb/whiteC.webp"
                  className="ourServC"
                  alt="whiteC"
                />
                <h1>{header}</h1>
              </div>
              <div className="row">
                <motion.div
                  className="col centerText"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ ease: "anticipate", duration: 1.5 }}
                >
                  <h5>
                    <i className={icon}></i>
                  </h5>

                  <p>{body}</p>
                  <div>
                    <p>{moreBody}</p>
                  </div>
                </motion.div>
              </div>
              <div className="centerText">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreDetails;
