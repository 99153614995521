import React, { useEffect, useState, lazy } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));

export default function Blogs() {
  const [blogData, setBlogData] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const getBlogs = async () => {
    try {
      const blogCollection = collection(db, "Blogs");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => doc.data()); // Get today's date in ISO format
      const today = new Date().toISOString().split("T")[0];
      const filteredBlogs = blogList.filter((blog) => {
        const blogDate = new Date(blog.Date).toISOString().split("T")[0];
        return blogDate <= today; // Keep blogs with date less than or equal to today
      });
      setBlogData(filteredBlogs);
    } catch (error) {
      console.error("Error fetching blog data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const extractSubtitleAndTrimBody = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Find and extract h1 or h2 tag
    const h1 = doc.querySelector("h1");
    const h2 = doc.querySelector("h2");
    const h3 = doc.querySelector("h3");

    let subtitle = "";
    if (h1) {
      subtitle = h1.textContent;
      h1.remove(); // Remove the h1 tag from the body
    } else if (h2) {
      subtitle = h2.textContent;
      h2.remove(); // Remove the h2 tag from the body
    } else if (h3) {
      subtitle = h3.textContent;
      h3.remove(); // Remove the h3 tag from the body
    }
    // Get the remaining body content (without the header)
    const trimmedBody = doc.body.innerHTML;

    return { subtitle, trimmedBody };
  };

  const handleCardClick = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(https://ik.imagekit.io/39dbkqr1gb/AdobeStock_223963392.jpeg?updatedAt=1730137680401)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.5, // Set opacity here
          zIndex: -1,
        }}
      ></div>
      <Header />
      <Container style={{ flex: 1, padding: "20px" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Row>
            {blogData.map((blog, index) => {
              // Extract subtitle and body without the header tag
              const { subtitle, trimmedBody } = extractSubtitleAndTrimBody(
                blog.Body
              );

              return (
                <Col md={6} lg={4} key={index} style={{ marginBottom: "20px" }}>
                  <Card
                    className="bg-dark text-white"
                    style={{
                      height: "100%",
                      position: "relative",
                      cursor: "pointer",
                      overflow: "hidden",
                    }}
                    onClick={() => handleCardClick(blog)}
                  >
                    <Card.Img
                      src={blog.Image}
                      alt="Card image"
                      style={{
                        height: "200px",
                        objectFit: "cover",
                        opacity: 0.7,
                      }}
                    />
                    <Card.ImgOverlay
                      style={{
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        padding: "20px",
                      }}
                    >
                      <Card.Title>{blog.Caption}</Card.Title>
                      <Card.Subtitle>{subtitle}</Card.Subtitle>
                      <Card.Text>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              trimmedBody.length > 40
                                ? `${trimmedBody.substring(0, 40)}...`
                                : trimmedBody,
                          }}
                        />
                      </Card.Text>
                      <Card.Text>{blog.Date}</Card.Text>
                    </Card.ImgOverlay>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>

      {/* Modal to display detailed blog information */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="xl"
        centered
        scrollable={true}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedBlog && selectedBlog.Caption}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedBlog && (
            <>
              <div dangerouslySetInnerHTML={{ __html: selectedBlog.Body }} />
              <p>{selectedBlog.date}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
}
