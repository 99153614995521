// servicesData.js
export const servicesData = {
    "ACCOUNTING SERVICE": "Our comprehensive accounting services streamline your business operations, giving you peace of mind and allowing you to focus on growing your business.",
    "BOOKKEEPING SERVICE": "Stay organized with our professional bookkeeping services. We handle your day-to-day transactions, maintain accurate records, and ensure your books are balanced.",
    "UTILITY BILLING": "Efficiently manage your utility expenses with our comprehensive utility billing services. We handle all aspects of billing for property, from generating accurate utility bills to processing payments. Our team ensures timely and precise billing, allowing clients to focus on other critical aspects of their operations. With our dedicated service, you can trust that your billing needs are handled professionally and efficiently.",
    "METER READING": "  Our dedicated team of meter readers visits mobile home parks to accurately record electric, gas, and water meter readings. We ensure timely and precise data collection to support efficient utility management and billing for your property.",
    "UTILITY RATES AUDITING": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "RENT MANAGER UTILITY BILLING": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "PROFESSIONAL SERVICES": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "CUSTOMER SATISFACTION": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
  };
  export const paragraphTwo = {
    "ACCOUNTING SERVICE": "Lorem ipsum dolor sit amet, consectetur adipiscing elit....",
    "BOOKKEEPING SERVICE": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "UTILITY BILLING": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "METER READING": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "UTILITY RATES AUDITING": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "RENT MANAGER UTILITY BILLING": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "PROFESSIONAL SERVICES": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    "CUSTOMER SATISFACTION": "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
  }