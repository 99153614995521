import React from 'react';

export default function MobilePrivacyPolicy() {
  const pdfUrl = process.env.PUBLIC_URL + "/PrivacyPolicy.pdf";

  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src={"https://docs.google.com/document/d/e/2PACX-1vSTZ3t7vdAYZBWg1geXOWp85Erxs3y1luxcYgdxLoMk_z-4A5PMHzr7u2ncNySUQDcOBUTglqmrM7R8/pub"}
        type="application/pdf"
        title="Privacy Policy PDF"
        style={{ height: '100%', width: '100%' }}
      >
      </iframe>
    </div>
  );
}
