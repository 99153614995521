import React, { Suspense, lazy } from "react";
import Spinner from "react-bootstrap/Spinner";
const Header = lazy(() => import("../components/Header"));
const Body = lazy(() => import("../components/homeBody"));
const Footer = lazy(() => import("../components/Footer"));
//Creates Home page component
function Home() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="Spinner">
            <Spinner animation="border" role="status">
              <span className=" centerText visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      >
        <Header />
        <Body />
        <Footer />
      </Suspense>
    </div>
  );
}
//Exports Home page component
export default Home;
