import React, { useEffect } from "react";
import Header from "../components/Header";
import ContactFooter from "../components/ContactFooter";
import ContactUs from "../components/contactForm";

// CSS for background, inline and camelCased for React
const backgroundImg = {
  minHeight: "90vh",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `linear-gradient(to right, rgba(102, 212, 234, 0.80), rgba(253, 150, 100, 0.55)), url(https://ik.imagekit.io/39dbkqr1gb/AdobeStock_639705704.jpeg?updatedAt=1730137767249)`,
};

function ContactPage() {
  // Scroll to top of page when navigated
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="container-fluid contactus " style={backgroundImg}>
        <div
          className="row contactStuff contactWrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className="col-lg-6 content">
            <ul>
              <div className="widget no-box">
                <h5 className="widget-title">
                  Contact Us<span></span>
                </h5>
              </div>
            </ul>
            <ul>
              <li>(800) 379-7426 Toll-Free</li>
              <li>(951) 929-3800 Local</li>
              <li>(951) 929-3899 Fax</li>
            </ul>
            <ul>
              <h5>Mailing Address </h5>
              <li>PO Box 2747</li>
              <li>Hemet, California 92546</li>
            </ul>
            <ul>
              <h5>Office Address</h5>
              <li>151 North Gilbert Street</li>
              <li>Hemet, California 92543</li>
            </ul>
          </div>
        </div>
        {/* Contact page Email form */}
        <ContactUs />
      </div>
      {/* Contact page footer */}
      <ContactFooter />
    </div>
  );
}

export default ContactPage;
