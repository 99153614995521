import React from 'react';

export default function MobilePrivacyPolicy() {
  const pdfUrl = process.env.PUBLIC_URL + "/RetailPrivacyPolicy.pdf";

  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src={"https://docs.google.com/document/d/e/2PACX-1vTKD-KmZjkQwsBK5RjopvDBkI1cQDIwHno1ZJY4onyA8Lz_Gkl18Naca0AigzN7z5988aH05Kv11JHc/pub"}
        type="application/pdf"
        title="Privacy Policy PDF"
        style={{ height: '100%', width: '100%' }}
      >
      </iframe>
    </div>
  );
}
