import React from "react";
import { Link } from "react-router-dom";
import Logo from "./partials/Svg";
import { motion } from "framer-motion";

function Header() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link className="navbar-brand" to="/">
            <Logo style={{ width: "70%", height: "50%" }} />
          </Link>

          {/* Centered RMUC 2024 button with ripple effect */}
          <div className="center-button" style={{ position: "relative" }}>
            {/* Ripple effect */}
            <motion.div
              style={{
                position: "absolute",
                top: "50%",
                left: "34%",
                width: 0,
                height: 0,
                backgroundColor: "rgba(1, 129, 192, 0.3)", // Light blue ripple color
                borderRadius: "50%",
                zIndex: 0,
                transform: "translate(-50%, -50%)",
              }}
              animate={{
                width: ["0px", "100px", "150px"],
                height: ["0px", "100px", "150px"],
                opacity: [1, 0], // Fade out as it expands
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatDelay: 1, // Add a small delay between repeats
              }}
            />
            {/* Centered RMUC 2024 button */}
            <button
              type="button"
              className="btn"
              style={{
                position: "relative", // Ensure it's above the ripple
                zIndex: 1,
                backgroundColor: "#0181c0", 
                color: "white",
                padding: 10,
                marginRight: 62,
              }}
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSdTsHGfRC_LBo1CD7KhHY7t6_KYhCIDtHI9yiqVw2GA5jkZ6g/viewform?usp=sf_link",
                  "_blank"
                )
              } // Link Google Form
            >
              RMUC 2024
            </button>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  data-testid="HomeBtn"
                  aria-current="page"
                  to="/"
                >
                  <button type="button" className="btn btn-outline-primary">
                    <strong> Home </strong>
                  </button>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  data-testid="AboutUsBtn"
                  to="/aboutUs"
                >
                  <button type="button" className="btn btn-outline-primary">
                    <strong> About Us </strong>
                  </button>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  data-testid="ourServ"
                  to="/ourServices"
                >
                  <button type="button" className="btn btn-outline-primary">
                    <strong> Our Services </strong>
                  </button>
                </Link>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  data-testid="PayRent"
                  href="https://vcs.payrent.com"
                >
                  <button type="button" className="btn btn-outline-primary">
                    <strong> Pay Your Bill </strong>
                  </button>
                </a>
              </li> */}
              <li className="nav-item dropdown"></li>
            </ul>
            <Link
              className="btn btn-outline-primary"
              data-testid="contactBtn"
              to="/contactpage"
            >
              <strong>Contact Us</strong>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
