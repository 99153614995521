import React, { useEffect } from "react";
import Phone from "./partials/Phone";
import Phone2 from "./partials/Phone2";
export default function LandingPageBody() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(https://ik.imagekit.io/39dbkqr1gb/LandingPageImage.jpeg?updatedAt=1728942534073)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.5, // Set opacity here
          zIndex: -1,
        }}
      ></div>

      <div className="centerText wrapper">
        <div style={{ borderRadius: 20 }}>
          <h1>VCS Quick Reads</h1>
        </div>
      </div>
      <div
        className="centerText container"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          position: "relative",
        }}
      >
        <div>
          <div
            style={{
              backgroundColor: "#90C541",
              color: "#fff",
              marginBottom: 10,
              padding: 10,
              width: "100%",
              marginTop: 10,
              borderRadius: 10,
            }}
          >
            <div
              style={{
                backgroundColor: "#000",
                padding: 7,
                borderRadius: 10,
                opacity: 0.7,
              }}
            >
              <h2>Easily Submit Utility Reads</h2>
            </div>
          </div>

          <Phone />
        </div>
        <div>
          <div
            style={{
              backgroundColor: "#90C541",
              color: "#fff",
              padding: 10,
              width: "100%",
              marginTop: 10,
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <div
              style={{
                backgroundColor: "#000",
                padding: 7,
                borderRadius: 10,
                opacity: 0.7,
              }}
            >
              <h2>Submit Photos For Each Read</h2>
            </div>
          </div>

          <Phone2 />
        </div>

        <div
          style={{
            borderRadius: 10,
            width: "100vw",
            backgroundColor: "#048dc9",
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          <div
            style={{
              padding: 50,
              margin: 10,
              borderRadius: 10,
              backgroundColor: "black",
              color: "#fff",
              opacity: 0.7,
            }}
          >
            <h1>Coming soon to the Google Play Store and Apple App Store</h1>
            <i className="fa-brands fa-google-play fa-4x"></i>
            <i className="fa-brands fa-app-store-ios fa-4x"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
