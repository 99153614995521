import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Spinner from "react-bootstrap/Spinner";
import * as serviceWorker from "./serviceWorkerRegistration";
//Renders app Component from App.js in root div on index.html

//StrictMode is a tool for highlighting potential problems in an application.
//StrictMode does not render any visible UI.
//It activates additional checks and warnings for its descendants.
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="Spinner">
          <br />
          <br />
          <Spinner animation="border" role="status">
            <span className=" visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>
);
serviceWorker.register({
  onUpdate: (registration) => {
    if (window.confirm("New version available! Would you like to refresh?")) {
      window.location.reload();
    }
  },
});